import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import axios from "axios";
import useSWR from "swr";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import moment from "moment-timezone";
import { AiOutlineLoading } from "react-icons/ai";

// Función fetcher para manejar la solicitud POST con axios
const fetcher = async ([url, id]) => {
    try {
        const response = await axios.post(url, { id });
        return response.data;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        throw new Error("Error al cargar datos");
    }
};

const Component = ({ params: { id } }) => {
    // Clave para useSWR, que incluye la URL del endpoint y el ID como parámetro
    const key = [`/.netlify/functions/convocatorias-get`, id];

    // Obtener datos y estado de carga desde useSWR
    const { data, error, isLoading } = useSWR(key, fetcher);

    const values = [
        {
            id: 1,
            title: "Título",
            accessor: "titol",
            value: null,
        },
        {
            id: 2,
            title: "Institución",
            accessor: "institucioDesenvolupat",
            value: null,
        },
        {
            id: 3,
            title: "Ámbito",
            accessor: "ambit",
            value: null,
        },
        {
            id: 4,
            title: "Expediente",
            accessor: "expedient",
            value: null,
        },
        {
            id: 5,
            title: "Fecha finalización",
            accessor: "dataFinalitzacio",
            value: d => (d ? moment(d).format("DD/MM/YYYY") : "-"),
        },
        {
            id: 6,
            title: "Año oposición",
            accessor: "anyOpo",
            value: null,
        },
        {
            id: 7,
            title: "Sistema de selección",
            accessor: "sistemaSeleccio",
            value: null,
        },
        {
            id: 8,
            title: "Grupo titulación",
            accessor: "grupTitulacio",
            value: null,
        },
        {
            id: 9,
            title: "Otros requisitos",
            accessor: "altresRequisits",
            value: null,
        },
        {
            id: 10,
            title: "Observaciones",
            accessor: "observacions",
            value: null,
        },
        {
            id: 11,
            title: "Estado",
            accessor: "estat",
            value: null,
        },
        {
            id: 12,
            title: "Fecha publicación documento",
            accessor: "maxDataPublicacioDocument",
            value: d => (d ? moment(d).format("DD/MM/YYYY") : "-"),
        },
        {
            id: 13,
            title: "Identificador",
            accessor: "identificador",
            value: null,
        },
        {
            id: 14,
            title: "Web CIDO",
            accessor: "urlCido",
            value: d =>
                d ? (
                    <>
                        <a
                            href={d}
                            title="Web CIDO"
                            className="hover:bg-ccyan duration-300 rounded bg-ccyan-dark text-white px-2 text-xs py-1 hidden md:inline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic para ir a la web
                        </a>

                        <a
                            href={d}
                            title="Web CIDO"
                            className="hover:bg-ccyan duration-300 rounded bg-ccyan-dark text-white px-2 text-xs py-1 md:hidden"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic
                        </a>
                    </>
                ) : null,
        },
        {
            id: 15,
            title: "Web",
            accessor: "urlWeb",
            value: d =>
                d ? (
                    <>
                        <a
                            href={d}
                            title="Web"
                            className="hover:bg-ccyan duration-300 rounded bg-orange-600 text-white px-2 text-xs py-1 hidden md:inline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic para ir a la web
                        </a>
                        <a
                            href={d}
                            title="Web"
                            className="hover:bg-ccyan duration-300 rounded bg-orange-600 text-white px-2 text-xs py-1 md:hidden"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic
                        </a>
                    </>
                ) : null,
        },
        {
            id: 16,
            title: "Acceso trámite",
            accessor: "accesTramit",
            value: d =>
                d ? (
                    <>
                        <a
                            href={d}
                            className="hover:bg-ccyan duration-300 rounded bg-blue-600 text-white px-2 text-xs py-1 hidden md:inline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic para ir a la web
                        </a>
                        <a
                            href={d}
                            className="hover:bg-ccyan duration-300 rounded bg-blue-600 text-white px-2 text-xs py-1 md:hidden"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic
                        </a>
                    </>
                ) : null,
        },
        {
            id: 17,
            title: "Web suscripción",
            accessor: "urlSubscripcio",
            value: d =>
                d ? (
                    <>
                        <a
                            href={d}
                            className="hover:bg-ccyan duration-300 rounded bg-teal-600 text-white px-2 text-xs py-1 hidden md:inline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic para ir a la web
                        </a>
                        <a
                            href={d}
                            className="hover:bg-ccyan duration-300 rounded bg-teal-600 text-white px-2 text-xs py-1 md:hidden"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clic
                        </a>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            {error ? (
                <>
                    <Seo title="Convocatoria no encontrada" />
                    <Layout>
                        <div className="py-20 container">
                            <Title>Convocatoria</Title>
                            <Text>
                                La convocatoria <strong>{id}</strong> no se ha encontrado. Puede que
                                ya no exista o que el identificador está mal.
                            </Text>
                        </div>
                    </Layout>
                </>
            ) : (
                <>
                    <Seo
                        title={
                            data
                                ? `${data?.item?.attributes?.titol} - ${data?.item?.attributes?.institucioDesenvolupat}`
                                : `Cargando...`
                        }
                    />

                    <Layout>
                        {isLoading && (
                            <div className="container my-20">
                                <AiOutlineLoading className="text-center w-5 h-5 animate-spin duration-300" />
                            </div>
                        )}

                        {data && (
                            <div className="py-20 container">
                                <Title>{data?.item?.attributes?.titol}</Title>

                                <div className="mt-5">
                                    <div className="overflow-y-auto rounded pb-5">
                                        <table className="table w-full rounded shadow border-separate border border-slate-500">
                                            <tbody className="font-montserrat text-gray-600 text-sm">
                                                {values?.map(({ id, title, accessor, value }) => {
                                                    return (
                                                        <tr
                                                            key={id}
                                                            className={`
                                                                rounded
                                                                    ${
                                                                        id % 2 !== 0
                                                                            ? "bg-gray-200 "
                                                                            : "bg-white "
                                                                    }
                                                                `}
                                                        >
                                                            <td className="py-3 px-5 font-semibold">
                                                                {title}
                                                            </td>
                                                            <td className="py-3 px-5">
                                                                {value
                                                                    ? value(
                                                                          data?.item?.attributes?.[
                                                                              accessor
                                                                          ],
                                                                      )
                                                                    : data?.item?.attributes?.[
                                                                          accessor
                                                                      ]}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/** Disclaimer */}
                                <div className="font-montserrat text-xs text-center md:text-right text-gray-500 mt-10 md:w-1/2 md:ml-auto">
                                    * Los resultados que se muestran en esta página están extraídos
                                    de la de{" "}
                                    <a
                                        href="https://dadesobertes.diba.cat/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="border-b hover:border-transparent duration-300 text-gray-600"
                                    >
                                        Dades Obertes de la DIBA
                                    </a>
                                    . iOpos no se ha responsable de la exactitud de estas.
                                </div>
                            </div>
                        )}
                    </Layout>
                </>
            )}
        </>
    );
};

export default Component;
